// -------------------
// Headers - - - - - -
// -------------------
const header = (operation: any) => {
   return {
      emailVerificationRequiredHeader: operation
         .getContext()
         .response?.headers.get("X-RP-Verification-Required") as "true" | "false" | undefined,
      twoFactorAuthRequiredHeader: operation
         .getContext()
         .response?.headers.get("X-RP-2FA-Required") as "true" | "false" | undefined,
      twoFactorAuthSetupHeader: operation.getContext().response?.headers.get("X-Rp-2fa-Setup") as
         | "true"
         | "false"
         | undefined,
      logoutHeader: operation.getContext().response?.headers.get("X-RP-Auth-Revoked") as
         | "true"
         | "false"
         | undefined,
      userSuspendedHeader: JSON.parse(
         operation.getContext().response?.headers.get("X-Rp-User-Suspended")
      ) as number | undefined,

      twoFactorAuthMethodHeader: operation.getContext().response?.headers.get("X-Rp-2fa-Method") as
         | "SMS"
         | "Email"
         | "Whatsapp"
         | undefined,
      twoFactorPhoneLastFourDigitsHeader: operation
         .getContext()
         .response?.headers.get("X-Rp-2fa-Phone-Last-Four") as string | undefined,
   }
}

export default header
