type App = "homepage" | "account" | "pro-app"
type Env = "development" | "stage" | "production"

const getAppAndEnv = () => {
   const url = window.location.href
   const urlArray = url.split("/")
   const appPath = urlArray[2]

   // app are "homepage", "account" or "pro-app"
   const app: App = appPath.includes("account")
      ? "account"
      : appPath.includes("pro")
      ? "pro-app"
      : "homepage"

   // get th env from the url, where if it ends with `.test` it's a development env, otherwise it's production or stage, if the name of the app has `stage` in it, it's stage, otherwise it's production
   const env: Env = appPath.includes("test")
      ? "development"
      : appPath.includes("stage")
      ? "stage"
      : "production"
   return { app, env }
}

export default getAppAndEnv
