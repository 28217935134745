import { LitElement, html, css, PropertyValueMap, unsafeCSS } from "lit"
import { customElement, state } from "lit/decorators.js"
import { UserMenuItemProps } from "../../components/user-menu-item"
import style from "./style.scss?inline"
import "../../components/user-menu-item"
import { ApolloQueryController, ApolloMutationController } from "@apollo-elements/core"
import { GetUserDocument, GetUserQuery, GetUserQueryVariables } from "./user.graphql"
import { GetRolesDocument, GetRolesQuery, GetRolesQueryVariables } from "./user.graphql"
import {
   SelectRoleDocument,
   SelectRoleMutation,
   SelectRoleMutationVariables,
} from "./user.graphql"
import { client } from "../../configs/ApolloClient/client"
import auth from "../../utils/auth"
import { refreshPoeToken } from "../../utils/poeToken"
import getPASETOFooter from "../../utils/getPASETOFooter"
import "../../components/user-menu-modal"

import { GetAppWithEnv } from "../../controllers/get-app-and-env-controller"

interface UserMenuModalProps {
   rolesData: UserMenuItemProps[]
   activeRoleId?: string
   selectRoleLoading?: boolean
}

interface ActiveRoleProps {
   displayName: string
   roleType: UserMenuItemProps["staticProps"]["role"] | undefined
   roleId: string | undefined
   avatarImage?: string | null
}

@customElement("user-menu")
export class UserMenu extends LitElement {
   // ------------------
   // Properties & State
   // ------------------
   @state()
   protected _showModal: boolean = false

   @state()
   protected _rolesData: UserMenuItemProps[] = []

   @state()
   protected _activeRole: ActiveRoleProps | undefined = undefined

   @state()
   protected _selectedRoleId: string | null = null

   app = new GetAppWithEnv(this).getAppAndEnv().app

   env = new GetAppWithEnv(this).getAppAndEnv().env

   // ----------
   // Components
   // ----------
   private component = {
      notLoggedInSlot: () => {
         return html` <slot name="not-logged-in"></slot> `
      },

      activeAvatar: () => {
         const isLoading =
            (this.getUserQuery.loading || this.getAvailableRolesQuery.loading) &&
            this._rolesData.length === 0
         // if the avatarImage exists, render the image element
         const loadingElement = html`<div class="avatar-loading">
            <span class="loader"></span>
         </div>`

         let element
         if (this._activeRole?.avatarImage) {
            element = html`<img class="avatar" src=${this._activeRole.avatarImage} />`
         } else {
            // if the avatarImage does not exist, render the initials
            element = html`<div class="avatar placeholder-avatar">
               ${this.method.getInitials(`${this._activeRole?.displayName}`)}
            </div>`
         }

         return html`<div
            class="active-avatar-container"
            @click=${!isLoading ? this.handle.toggleModal : null}
            data-role-type=${this._activeRole?.roleType || ""}
         >
            ${isLoading ? loadingElement : element}
         </div>`
      },

      userMenuDropdown: () => {
         return html`<div class="user-menu-dropdown" ?data-show-modal=${this._showModal}>
            <user-menu-modal
               .rolesData=${this._rolesData}
               .activeRoleId=${this._activeRole?.roleId}
               @selectRole=${this.handle.selectRole}
               ?selectRoleLoading=${true}
               @manageRoles=${this.handle.manageRoles}
               ?getRolesLoading=${this.getAvailableRolesQuery.loading &&
               this._rolesData.length === 0}
               .selectedRoleId=${this._selectedRoleId}
               @closeModal=${this.handle.closeModal}
               @signOut=${this.handle.signOut}
               @addRole=${this.handle.addRole}
               @manageAccount=${this.handle.manageAccount}
               @gotoPortal=${this.handle.gotoPortal}
            >
            </user-menu-modal>
         </div>`
      },
   }

   // ----------------------------------
   // Apollo GraphQL Queries & Mutations
   // ----------------------------------
   getUserQuery = new ApolloQueryController<GetUserQuery, GetUserQueryVariables>(
      this,
      GetUserDocument,
      {
         client,
         noAutoSubscribe: true,
         onData: () => this.method.mapUserData(),
         fetchPolicy: "cache-and-network",
      }
   )
   getAvailableRolesQuery = new ApolloQueryController<
      GetRolesQuery,
      GetRolesQueryVariables
   >(this, GetRolesDocument, {
      client,
      noAutoSubscribe: true,
      onData: () => this.method.mapRolesData(),
      fetchPolicy: "cache-and-network",
   })

   selectRoleMutation = new ApolloMutationController<
      SelectRoleMutation,
      SelectRoleMutationVariables
   >(this, SelectRoleDocument, {
      client,
      onCompleted: (data) => {
         refreshPoeToken()
         this.handle.gotoPortal(data?.assumeRole.token as string)
      },
   })

   // ----------------------
   // Render Method - - - 🏗️
   // ----------------------
   render() {
      this.checkForRequiredSlot() // check for required slot element(s)
      const isLoading =
         (this.getUserQuery.loading || this.getAvailableRolesQuery.loading) &&
         this._rolesData.length === 0
      return html`
         <div class="user-menu">
            ${this._activeRole || isLoading
               ? this.component.activeAvatar()
               : this.component.notLoggedInSlot()}
            ${this._rolesData.length >= 1 ? this.component.userMenuDropdown() : null}
         </div>
      `
   }

   // ---------------
   // Methods & Logic
   // ---------------
   private method = {
      getInitials: (displayName: string): string => {
         // get the first 2 Letters of the first and last name, also check if the last name exists
         const [firstName, lastName] = displayName.split(" ")
         const firstInitial = firstName[0].toUpperCase()
         const lastInitial = lastName ? lastName[0].toUpperCase() : ""
         return `${firstInitial}${lastInitial}`
      },

      getRoleAddress: (address: GetRolesQuery["roles"][0]["address"]): string | null => {
         if (!address) return null
         return `${address.street ? address.street : ""}${
            address.city ? ", " + address.city : ""
         }${address.state ? ", " + address.state : ""}${
            address.postalCode ? ", " + address.postalCode : ""
         }`
      },

      mapRolesData: () => {
         // call _activeRole from the UserMenu class

         // map the rolesData from the getAvailableRolesQuery.data
         this._rolesData = this.getAvailableRolesQuery.data?.roles.map((role) => {
            return {
               staticProps: {
                  role: role.type,
                  displayName: role.displayName,
                  avatarImage: role.avatar?.droplet?.children[1]?.url || role.avatar?.url,
                  roleId: role.id,
                  tags: role.badges,
                  email: role.email,
                  companyName: role.companyName,
                  address: this.method.getRoleAddress(role.address),
               },
               hide: false,
               loading: false,
            } as UserMenuItemProps
         }) as UserMenuItemProps[]
         // set the active role from the authToken if (roleId && userId) exist in the token
         const token = auth.getAccessToken()
         const { roleId, userId } = getPASETOFooter(token as string)
         if (!roleId || !userId) return
         this.method.setActiveRole(roleId)
      },

      setActiveRole: (roleId: string) => {
         // Get the active role from the rolesData
         const activeRole = this._rolesData.find(
            (role) => role.staticProps.roleId === roleId
         )

         // Set the active role
         this._activeRole = {
            displayName: activeRole?.staticProps.displayName as string,
            roleType: activeRole?.staticProps
               .role as UserMenuItemProps["staticProps"]["role"],
            roleId: activeRole?.staticProps.roleId,
            avatarImage: activeRole?.staticProps.avatarImage,
         }
      },

      mapUserData: () => {
         const data = this.getUserQuery.data
         this._activeRole = {
            displayName: (data?.user.firstName + " " + data?.user.lastName) as string,
            roleType: undefined,
            roleId: undefined,
            avatarImage: undefined,
         }
         this.requestUpdate()
      },

      getLocation: () => {
         let homepage = ""
         switch (this.env) {
            case "development":
               homepage = "https://rentpost.test"
               break
            case "stage":
               homepage = "https://stage.rentpost.com"
               break
            case "production":
               homepage = "https://rentpost.com"
               break
            default:
               homepage = "https://rentpost.com"
               break
         }

         let account = ""
         switch (this.env) {
            case "development":
               account = "https://account.rentpost.test"
               break
            case "stage":
               account = "https://account.stage.rentpost.com"
               break
            case "production":
               account = "https://account.rentpost.com"
               break
            default:
               account = "https://account.rentpost.com"
               break
         }

         let proApp = ""
         switch (this.env) {
            case "development":
               proApp = "https://pro.rentpost.test"
               break
            case "stage":
               proApp = "https://pro.stage.rentpost.com"
               break
            case "production":
               proApp = "https://pro.rentpost.com"
               break
            default:
               proApp = "https://pro.rentpost.com"
               break
         }

         return {
            homepage,
            account,
            proApp,
         }
      },

      gotoAccountPath: (path: string) => {
         switch (this.app) {
            case "account":
               location.assign(`/${path}`)
               break
            case "pro-app":
            case "homepage":
               location.assign(`${this.method.getLocation().account}/${path}`)
               break
         }
      },
   }

   // ---------------------
   // Custom Event Handlers
   // ---------------------
   private handle = {
      closeModal: (): void => {
         this._showModal = false
      },

      showModal: (): void => {
         this._showModal = true
      },

      toggleModal: (): void => {
         this._showModal = !this._showModal
      },

      signOut: (): void => {
         this.method.gotoAccountPath("logout")
      },

      manageRoles: (): void => {
         // TODO: add a manage roles button to the modal
      },

      loadUserData: (): void => {
         this.getUserQuery.subscribe()
      },

      loadRolesData: (): void => {
         this.getAvailableRolesQuery.subscribe()
      },

      selectRole: (e: { detail: { roleId: string } }): void => {
         this._selectedRoleId = e.detail.roleId
         // Set the active role
         this.method.setActiveRole(e.detail.roleId)
         // Run assumeRole mutation to get a new paseto token
         this.selectRoleMutation.mutate({
            variables: {
               id: e.detail.roleId,
            },
         })
      },

      gotoPortal: (token: string): void => {
         // set access token in the cookie
         auth.setAccessToken(token, getPASETOFooter(token).expiration)
         const roleType = this._activeRole?.roleType
         let URL = ""
         const getPortal = () => {
            let portal = ""
            switch (roleType) {
               case "tenant":
                  portal = `myrent`
                  break
               case "manager":
                  portal = `mypost`
                  break
               case "owner":
                  portal = `myplace`
                  break
               case "employee":
                  portal = `control`
                  break
            }
            return portal
         }
         switch (this.app) {
            case "account":
            case "homepage":
               URL = `${this.method.getLocation().proApp}/${getPortal()}`
               break
            case "pro-app":
               URL = `/${getPortal()}`
               break
         }

         location.assign(URL)
         // close the modal
         this._showModal = false
      },

      addRole: (): void => {
         this.method.gotoAccountPath("start")
      },

      manageAccount: (): void => {
         // Open the account page in a new tab
         switch (this.app) {
            case "account":
               window.location.assign(`/account`)
               break
            case "pro-app":
            case "homepage":
               window.location.href = `${this.method.getLocation().account}/account`
               break
         }
         this.handle.closeModal()
      },

      onMount: (): void => {
         const token = auth.getAccessToken()

         if (!token) return // if there is no token, do nothing

         const { roleId, userId } = getPASETOFooter(token)
         const isTokenExpired = auth.isTokenExpired(token)

         // if the token is expired, remove the token from the cookie and reload the page
         if (isTokenExpired) {
            return auth.removeAccessToken()
         }

         // if the token is not expired, set the active role
         if (roleId && userId) {
            return this.handle.loadRolesData()
         }

         if (userId) {
            this.handle.loadRolesData()
            return this.handle.loadUserData()
         }
      },

      clickOutside: (e: Event): void => {
         const element = this.shadowRoot?.querySelector(".user-menu-dropdown")
         if (!element) return

         if (!e.composedPath().includes(this)) {
            this.handle.closeModal()
         }
      },
   }

   // -----------------
   // Lifecycle Methods
   // -----------------
   protected firstUpdated(): void {
      // Run required graphql operations on mount
      this.handle.onMount()

      // Add event listeners for the click outside of the modal
      document.addEventListener("click", this.handle.clickOutside)
   }

   // --------------
   // Required Slots
   // --------------
   checkForRequiredSlot() {
      const slotElement = this.querySelector(`*[slot='not-logged-in']`)
      if (slotElement === null) {
         throw Error(`Required slot element [name=not-logged-in] not found`)
      }
   }

   protected updated(
      _changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
   ): void {
      //@ts-ignore
      _changedProperties.forEach((oldValue, propName) => {
         if (propName === "_showModal") {
            if (!this._showModal) {
               setTimeout(() => {
                  this._selectedRoleId = null
               }, 600)
            }
         }
      })
   }

   // -------
   // Styling
   // -------
   static readonly styles = css`
      ${unsafeCSS(style)}
   `
}

export type { UserMenuModalProps as UserMenuItemProps }
declare global {
   interface HTMLElementTagNameMap {
      "user-menu": UserMenu
   }
}
