import { ErrorResponse } from "@apollo/client/link/error"
import getAppAndEnv from "src/utils/getAppWithEnv"
import header from "./headers"

const graphqlErrorHandler = (error: ErrorResponse) => {
   const { networkError, operation } = error
   // @ts-ignore  - This is correct, but the TS definition is wrong
   const statusCode = networkError?.statusCode

   // -------------------
   // Headers - - - - - -
   // -------------------
   const { logoutHeader } = header(operation)

   let logoutPageURL = `https://account.rentpost.com/logout`
   const env = getAppAndEnv().env
   switch (env) {
      case "development":
         logoutPageURL = `https://account.rentpost.test/logout`
         break
      case "stage":
         logoutPageURL = `https://account.stage.rentpost.com/logout`
         break
      case "production":
         logoutPageURL = `https://account.rentpost.com/logout`
         break
      default:
         logoutPageURL = `https://account.rentpost.com/logout`
         break
   }

   // *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
   // *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
   // ERROR HANDLING LOGIC - 🚧 order matters 🚧
   // *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
   // *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

   // ----------------
   // 1- Logout Header
   // ----------------
   if (logoutHeader === "true") {
      window.location.href = logoutPageURL
      return
   }

   // --------------
   // 2- IF 401 ONLY
   // --------------
   if (statusCode === 401) {
      // reason query - this will be used to show a message on the login page
      const reasonQuery = `?reason=Session expired. Please sign in again.`
      // redirect query - this will be used to redirect the user back to the page they were on after logging in
      const redirectQuery = `&return_to=${location.href}`
      window.location.href = logoutPageURL + reasonQuery + redirectQuery
      return
   }
}

export default graphqlErrorHandler
