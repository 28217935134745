import { v4 as uuid } from "uuid"

// store poe token in session storage to persist across page refreshes
let poeToken = sessionStorage.getItem("poeToken")
if (!poeToken) {
   poeToken = uuid()
   sessionStorage.setItem("poeToken", poeToken)
}

export const refreshPoeToken = () => {
   sessionStorage.setItem("poeToken", uuid())
}

export default poeToken
