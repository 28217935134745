// @ts-ignore
import getPASETOFooter from "./getPASETOFooter"
import moment from "moment"
import getCookieValue from "./getCookieValue"
import getAppAndEnv from "./getAppWithEnv"

enum TOKEN {
   ACCESS_TOKEN = "account_access_token",
   ACCESS_TOKEN_STAGE = "account_access_token_stage",
}
const env = getAppAndEnv().env

const accessToken = env === "stage" ? TOKEN.ACCESS_TOKEN_STAGE : TOKEN.ACCESS_TOKEN

const domain = env === "development" ? ".rentpost.test" : ".rentpost.com"

const auth = {
   // --------------------------
   // Set access token in cookie
   // --------------------------
   setAccessToken: (token: string, expiration: string, remember?: boolean) => {
      // create cookie using plain js and adding expires attribute to expire date if remember is true other wise expires attribute will be empty

      const expirationDate: Date = new Date(expiration)

      // if remember is false, set a session "removeAccessTokenAfterSession" cookie to true
      if (remember === false) {
         sessionStorage.setItem("isAccessTokenValidForOneSession", "true")
      }
      const isAccessTokenValidForOneSession =
         sessionStorage.getItem("isAccessTokenValidForOneSession") === "true"

      // ******** COOKIE ********
      const Cookie = `${accessToken}=${token}; path=/; ${
         isAccessTokenValidForOneSession ? "" : `expires=${expirationDate};`
      } domain=${domain}; secure=true; samesite=lax`

      document.cookie = Cookie
   },

   // ----------------------------
   // Get access token from cookie
   // ----------------------------
   getAccessToken: () => getCookieValue(accessToken),

   // -------------------------------
   // Remove access token from cookie
   // -------------------------------
   removeAccessToken: () => {
      document.cookie = `${accessToken}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${domain}`
   },

   // -------------------------
   // Check if token is expired
   // -------------------------
   isTokenExpired: (token: string) => {
      const isAccessTokenValidForOneSession =
         sessionStorage.getItem("isAccessTokenValidForOneSession") === "true"

      const expirationDate = getPASETOFooter(token).expiration

      // use moment to get the current date in UTC format, then convert it to a number, then find the difference between the expiration date and the current date
      const now = moment.utc().valueOf()
      const expiration = moment.utc(expirationDate).valueOf()
      const timeDifference = expiration - now
      const isExpired = timeDifference < 0

      return isAccessTokenValidForOneSession ? false : isExpired
   },
}

export default auth
