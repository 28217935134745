import { decode } from "base-64"

interface footer {
   userId: string | null
   roleId: string | null
   expiration: string
}

const getPASETOFooter = (token: string): footer => {
   const footer = decode(token.split(".")[3])
   return JSON.parse(footer)
}

export default getPASETOFooter
