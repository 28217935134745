import { ApolloLink } from "@apollo/client/core"
import { getMainDefinition } from "@apollo/client/utilities"
import auth from "../../utils/auth"
import poeToken from "../../utils/poeToken"
import getAppAndEnv from "src/utils/getAppWithEnv"

interface DefinitionType {
   kind: string
   operation?: string
}

// ====================
// POE TOKEN MIDDLEWARE
// ====================
const poeTokenMiddleware = new ApolloLink((operation, forward) => {
   const { kind, operation: operationType }: DefinitionType = getMainDefinition(
      operation?.query
   )
   if (kind === "OperationDefinition" && operationType === "mutation") {
      operation.setContext(({ headers = {} }) => ({
         headers: {
            ...headers,
            POE: poeToken,
         },
      }))
   }

   return forward(operation)
})

// =======================================
// ADD AUTHENTICATION MIDDLEWARE TO APOLLO
// =======================================
const authMiddleware = new ApolloLink((operation, forward) => {
   // check that the operation is not a mutation "login"
   const { kind, operation: operationType }: DefinitionType = getMainDefinition(
      operation?.query
   )

   // ---------------
   // Logout Page URL
   // ---------------
   let logoutPageURL = `https://account.rentpost.com/logout`
   const env = getAppAndEnv().env
   switch (env) {
      case "development":
         logoutPageURL = `https://account.rentpost.test/logout`
         break
      case "stage":
         logoutPageURL = `https://account.stage.rentpost.com/logout`
         break
      case "production":
         logoutPageURL = `https://account.rentpost.com/logout`
         break
      default:
         logoutPageURL = `https://account.rentpost.com/logout`
         break
   }

   if (kind === "OperationDefinition" && operationType === "mutation") {
      const operationName = operation?.operationName
      if (operationName === "Login") {
         return forward(operation)
      }
   }
   const authorizationToken =
      operation.getContext().authorization || auth.getAccessToken()
   if (authorizationToken) {
      operation.setContext(({ headers = {} }) => ({
         headers: {
            ...headers,
            authorization: `Bearer ${authorizationToken}`,
         },
      }))
   }

   // ---------------------------
   // Expired authorization token
   // ---------------------------
   if (authorizationToken && auth.isTokenExpired(authorizationToken)) {
      // reason query - this will be used to show a message on the login page
      const reasonQuery = `?reason=Session expired. Please sign in again.`
      // redirect query - this will be used to redirect the user back to the page they were on after logging in
      const redirectQuery = `&return_to=${location.href}`
      window.location.href = logoutPageURL + reasonQuery + redirectQuery
      // @ts-ignore
      return forward({})
   }

   // ---------------------------
   // Missing authorization token
   // ---------------------------
   if (!authorizationToken) {
      // reason query - this will be used to show a message on the login page
      const reasonQuery = `?reason=Session expired. Please sign in again.`
      // redirect query - this will be used to redirect the user back to the page they were on after logging in
      const redirectQuery = `&return_to=${location.href}`
      window.location.href = logoutPageURL + reasonQuery + redirectQuery
      // @ts-ignore
      return forward({})
   }

   // -------------------------
   // Valid authorization token
   // -------------------------
   return forward(operation)
})

// =====================================
// ADD CURRENT URL TO WITH EVERY REQUEST
// =====================================
const currentURLMiddleware = new ApolloLink((operation, forward) => {
   const currentLocation: string = location.href
   operation.setContext(({ headers = {} }) => ({
      headers: {
         ...headers,
         "X-RP-Current-URL": currentLocation,
         "X-RP-Module": "web-component",
      },
   }))
   return forward(operation)
})

export { poeTokenMiddleware, authMiddleware, currentURLMiddleware }
