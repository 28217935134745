import {
   ApolloClient,
   InMemoryCache,
   ApolloLink,
   HttpLink,
   DefaultOptions,
} from "@apollo/client/core"
import { authMiddleware, poeTokenMiddleware, currentURLMiddleware } from "./middleware"
import getAppAndEnv from "../../utils/getAppWithEnv"
import { persistCache, SessionStorageWrapper } from "apollo3-cache-persist"
import { onError } from "@apollo/client/link/error"
import graphqlErrorHandler from "./graphqlErrorhandler"

let uri = ""
const env = getAppAndEnv().env

switch (env) {
   case "development":
      uri = "https://api.rentpost.test/graphql"
      break
   case "stage":
      uri = "https://api.stage.rentpost.com/graphql"
      break
   case "production":
      uri = "https://api.rentpost.com/graphql"
      break
   default:
      uri = "https://api.rentpost.com/graphql"
      break
}

export const cache = new InMemoryCache({
   // typePolicies: {
   //    roles: {
   //       keyFields: [
   //          "id",
   //          "displayName",
   //          "companyName",
   //          "email",
   //          "status",
   //          "badges",
   //          "type",
   //          "address",
   //          "avatar",
   //          ["url", "droplet"],
   //       ],
   //    },
   // },
})
// await before instantiating ApolloClient, else queries might run before the cache is persisted
persistCache({
   cache,
   storage: new SessionStorageWrapper(window.sessionStorage),
})
// Default options for all queries, mutations and subscriptions
const defaultOptions: DefaultOptions = {
   watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore",
   },
   query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
   },
   mutate: {
      errorPolicy: "all",
   },
}
export const client = new ApolloClient({
   cache,
   defaultOptions,

   link: ApolloLink.from([
      onError(graphqlErrorHandler),
      poeTokenMiddleware,
      authMiddleware,
      currentURLMiddleware,
      new HttpLink({ uri }),
   ]),
   connectToDevTools: true,
})
